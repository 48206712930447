.ButtonPasserEnLigne {
  background-color: #f5f5f5;
  color: #000;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  transition: 0.3s;
  padding: 15px;
}

.ButtonPasserEnLigne:hover {
  background-color: #e1e9e5;
  color: #000;
  font-size: 20px;
  font-weight: bold;
}
