.DisplayProduct {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  overflow-y: auto;
  max-height: 600px;
}

.VignetteProduit {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 15px;
  display: grid;
  grid-template-columns: 1fr 5fr;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #f7f7f7;
  border-radius: 5px;
  cursor: pointer;
}
.VignetteProduit:hover {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}
.imgProd {
  max-height: 80px;
}
.Description {
  margin-left: 10px;
}
