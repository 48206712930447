.dishCard {
  display: grid;
  grid-template-columns: 2fr 3fr;
  width: 45%;
  max-width: 45%;
  height: 80px;
  border: 1px solid black;
  border-radius: 5px;
  margin-right: 1%;
  background-color: white;
  margin-bottom: 5px;
  padding: 10px;
}

.dishCard:hover {
  border: 2px solid pink;
}

.SellingPriceDish {
  font-size: 1em;
  font-weight: bold;
  color: purple;
}
